@media (min-width: 990px) {
.navbar-header {
    margin-top: 18px!important;
    }

}
.dropdown-menu,.dropdown.open{
    background-color:none!important;
}
.dropdown.open,.navbar-right .dropdown-menu,.open,#user >.dropdown >.open{
    background-color: #6ABB43!important;
}

.dropdown-menu > li{
   /* border: 1px solid #FFF!important;*/
    color:#465664!important;
}

.dropdown-menu>li:not(:first-child) a {
    border-top: .1rem solid #fff;
}

.wallet .head-belt {
    background-image: url(images/wallet_interval.jpg);
    background-position: bottom;
}

.contact-us .head-belt {
    background-image: url(images/interval_contact.jpg);
    background-position: bottom;
}
#search input[type="button"]{
    background-color: #002157!important;
    color: #fff!important;
    border-radius: 0.2rem!important;
    cursor: pointer!important;
    border: none;
    width: 100%;
    font-size: 1.4rem;
    display: block;
    padding: 1.3rem;
    text-align: center;
}

#wrapper #what-is {
    background-color: #007ac2!important;
}

#wrapper #search {
    padding: 11.1rem 0;
    color: white;
    background-image: url(images/HTCMainPC.jpg);
    background-size: cover;
    background-position: center bottom;
}

#header, footer {
    background-color: #465664;
    color: white;
}

#header #currencies span {
    color: #465664;
    background-color: #d3d6da;
}

#header #signin span, #header #user span {
    margin-right: .5rem;
    background-image: url(images/user-icon.png);
}

#header #signup span {
    margin-right: .5rem;
    background-image: url(images/check-icon.png);
}

#header #wallet span.icon {
    background-image: url(images/wallet-icon.png);
}
